.sectionAButton {
    /* Initial state */
    transform: scale(1);
    /* Animation settings */
    animation: scaleAnimation 0.5s infinite;
    animation-play-state: running; /* The animation is running by default */
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
  }
  
  .sectionAButton:hover {
    animation-play-state: paused; /* Pause the animation on hover */
    transform: scale(1); /* Ensure it stays at the current scale when hovered */
    box-shadow: none; /* Remove the box-shadow on hover */
  }
  
  @keyframes scaleAnimation {
    0%, 100% {
      transform: scale(1); /* Start and end at scale 1 */
      box-shadow: none; /* No shadow at normal scale */
    }
    50% {
      transform: scale(1.02); /* Scale up slightly at the midpoint */
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow when scaling */
    }
  }


  /* Section B */
  .priciple_card:hover{
     margin-bottom: 30px;
     transform: scale(1.03);
  }


/* Section C */
.card{
    margin-left: -1000px;
}

.returncard{
    margin-left: 0px;
}

.card2{
    height: 0px;
}
@keyframes increaseHeight {
    0% {
      height: 0%;
    }
    100% {
      height: 100%;
    }
  }
  
  .returncard2 {
    animation: increaseHeight 5s forwards;
  }


  @keyframes expandHeight {
    0% { height: 0px; }
    100% { height: 100%; }
  }
  
  .animate-card1 {
    animation: expandHeight 0.5s ease-in-out forwards;
  }
  
  .animate-card2 {
    animation: expandHeight 0.5s ease-in-out 0.2s forwards;
  }
  
  .animate-card3:hover {
    animation: expandHeight 5s ease-in-out 0.4s forwards;
  }
  


@media screen and (max-width: 1024px) {

   .card,.card2{
        margin-left: -1000px;
    }
    
    .returncard,.returncard2{
        margin-left: 0px;
    }

    .card2{
        margin-bottom: auto;
    }
    .returncard2{
        margin-bottom:auto
    }
    
}





